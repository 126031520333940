/* eslint-disable */
import React, { useEffect, useState } from 'react';
import "./index.css"
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import displayText from './displayText';
import ConvertBase64ToImg from './convertBase64ToImg';
import ErrorMessage from './errorMessage'
import imgs from '../img/logo.png';
import text from '../img/Text.png';

const Screen2 = (error, name, surname, img, index, date, device, time, deviceName, temperatureStatus) => {

    const windows = window.innerHeight
    const [screens, setScreens] = useState([
        { deviceNumber: null, deviceData: {} },
        { deviceNumber: null, deviceData: {} },
    ])
    const [refresh, setRefresh] = useState(false);
    const showScreen1 = screens[0].deviceData;
    const showScreen2 = screens[1].deviceData;
    const [timeOut, setTimeOut] = useState(50000);
    const addDataToScreen = (i, deviceNumber, deviceData) => {
        setScreens(screens => {
            if (i !== -1) {
                screens[i] = {
                    deviceNumber,
                    deviceData
                }
            }
            return [...screens];
        });
    };

    useEffect(() => {
        function setData(deviceNumber, deviceData) {
            for (var i = 0; i < screens.length; i++) {
                if (!screens[i].deviceNumber) {
                    addDataToScreen(i, deviceNumber, deviceData)
                    break;
                }
            }
        }
        const indexData = screens.findIndex((screen) => screen.deviceNumber === device)
        if (indexData !== -1) {
            addDataToScreen(indexData, device, { device, name, surname, img, date, time, deviceName, temperatureStatus })
        } else if (device !== undefined) {
            setData(device, { device, name, surname, img, date, time, deviceName, temperatureStatus })
        }
    }, [device, surname, name, img, date, time, temperatureStatus])

    const orientation = 'Horizontal';
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            marginRight: 50,
            marginLeft: 50,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
        },
        styleDeviceName: {
            marginTop: -39,
            textAlign: "-webkit-right",
            color: "#cacaca",
            fontSize: 11
        },
        color: {
            color: "#000000",
            fontSize: 20,
        },
        styleLogoImg: {
            width: 100,
            position: 'absolute',
            marginTop: -23,
        },
        styleLogoImg2: {
            width: "50%",
            position: 'absolute',
            marginTop: "-1.6%"
        }
    }));

    useEffect(() => {
        if (refresh) {
            setTimeOut(50000)
        }
        setRefresh(true)
        setTimeout(function () {
            window.location.reload();
        }, timeOut);
    }, [time])

    const classes = useStyles();
    return (
        <>
            {error ? <ErrorMessage /> : null }
            <Grid container xs={12}>
                <Grid item xs={6}>
                    <Paper className={classes.paper} xs={6}>
                        <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12" style={{ textAlign: 'end', marginRight: 32 }} >
                            <img className={classes.styleLogoImg} src={imgs} />
                        </div>
                        {displayText
                            (
                                showScreen1.name,
                                showScreen1.surname,
                                showScreen1.time,
                                showScreen1.date,
                                showScreen1.deviceName,
                                showScreen1.img == undefined ? -46 : 46,
                                classes.styleColor,
                                orientation,
                                showScreen1.temperatureStatus,
                                classes.styleDeviceName
                            )
                        }
                        <img key={index}
                            src={ConvertBase64ToImg(showScreen1.img, showScreen1.name)}
                            style={{
                                width: "100%",
                                height: windows - 195,
                                marginBottom: showScreen1.img == undefined ? 40 : 42,
                                marginTop: showScreen1.img == undefined ? '11%' : '-1.5%',
                                border: '3px solid #ffc800'
                            }}
                            className={"cover"}
                            alt=""
                        />
                        <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12" style={{ textAlign: 'center' }} >
                            <img className={classes.styleLogoImg2} src={text} />
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        {displayText
                            (
                                showScreen2.name,
                                showScreen2.surname,
                                showScreen2.time,
                                showScreen2.date,
                                showScreen2.deviceName,
                                showScreen2.img == undefined ? -46 : 46,
                                classes.styleColor, orientation,
                                showScreen2.temperatureStatus,
                                classes.styleDeviceName
                            )
                        }
                        <img key={index}
                            src={ConvertBase64ToImg(showScreen2.img, showScreen2.name)}
                            style={{
                                width: "100%",
                                height: windows - 195,
                                marginBottom: showScreen2.img == undefined ? 40 : 42,
                                marginTop: showScreen2.img == undefined ? '11%' : '-1.5%',
                                border: '3px solid #ffc800'
                            }}
                            className={"cover"}
                            alt=""
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default Screen2;
