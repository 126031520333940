import React from 'react';
import ConvertBase64ToImg  from './convertBase64ToImg';
import displayText  from './displayText';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const ComponentsSceen = (index, name, surname, time, date, device, deviceName, styletext, stylecolor, orientation, cardRight, cardLeft, img, width, height, temperatureStatus, styleDeviceName) => {
    return (
    <>
        <Grid item xs={8} key={'img'+index} >
            <Paper className={cardRight}>
                <img key={index} src={ConvertBase64ToImg(img, name)} style={{ width: width, height: height}} className={"cover"} alt="" />
            </Paper>
        </Grid>
        <Grid item xs={4} key={'text'+index} >
            <Paper className={cardLeft}>
                {displayText(name, surname, time, date, deviceName, styletext, stylecolor, orientation, temperatureStatus, styleDeviceName)}
            </Paper>
        </Grid>
    </>
    )
}
export default ComponentsSceen;
