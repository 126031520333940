import React from 'react'
import { useEffect, useState } from 'react';
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
import AccessDisplay from '../components/index'
import "./index.css"
import Screen1 from '../components/screen1'
import Screen2 from '../components/screen2'
import Screen4 from '../components/screen4'

const HomeScreen = () => {
	const [data, setData] = useState([])
	const deviceNumbar = parseInt(process.env.REACT_APP_DEVICE_NUMBER);
	const [connection, setConnection] = useState(null);
	const [error, setError] = useState();

	useEffect(() => {
		const connection = new HubConnectionBuilder()
			.configureLogging(LogLevel.Debug)
			.withUrl(process.env.REACT_APP_SERVER_URL,process.env.REACT_APP_WEBSOCKET_FORCED === "true" &&
				({
					skipNegotiation: true,
					transport: HttpTransportType.WebSockets,
				}))
			.withAutomaticReconnect()
			.build();
		setConnection(connection);
	}, []); // eslint-disable-line

	useEffect(() => {
		if (connection) {
			connection.start()
				.then(result => {
					setError(false)
					connection.on('access-record', (name, surname, img, unixTime, device, deviceName, temperatureStatus) => {
						const dateObject = new Date(unixTime * 1)
						const time = dateObject.toLocaleTimeString()
						const date = dateObject.toLocaleDateString(
							'en-GB', {
								year: 'numeric',
								month: 'long',
								day: 'numeric' 
							  }
						)
						setData({ name, surname, img, device, time, date, deviceName, temperatureStatus})
					});
				})
				.catch(e => setError(true));
		}
	}, [connection]);

	const display = () => {
			if (data.length === 0) {
				if (deviceNumbar === 1) {
					return Screen1(error)
				} else if (deviceNumbar === 2) {
					return Screen2(error)
				} else if (deviceNumbar === 4){
					return Screen4(error)
				}
			} else {
				return AccessDisplay(error, data.name, data.surname, data.img, 0, data.date, data.device, deviceNumbar, data.time, data.deviceName, data.temperatureStatus)
			}
		}

	return (
		<>
			{display()}
			{version(process.env.REACT_APP_VERSION)}
		</>
	);
}

const version = (versionNumber) => {
	return (
		<div className="version">
			V. {versionNumber}
		</div>
	);
};

export default HomeScreen;
