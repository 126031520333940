import React from 'react';

const TemperatureStatus = (temperatureStatus) => {
        if (temperatureStatus === 1) {
            return (
                <h4 style={{color: "#ff4747", fontSize: 25, marginTop: 34, marginBottom: 25 }}>{"Suspected Fever"}</h4> 
            )
        } else if (temperatureStatus === 2) {
            return (
                <h4 style={{color: "#00ea00", fontSize: 25, marginTop: 34, marginBottom: 25}}>{"Normal"}</h4>
            )
        } else if (temperatureStatus === 3) {
            return (
                <h4 style={{color: "#d6d600", fontSize: 25, marginTop: 34, marginBottom: 25}}>{"Abnormal Low Temperature"}</h4>
            )
        } else if (temperatureStatus === 4){
            return (
                <h4 style={{fontSize: 25, marginTop: 34, marginBottom: 25}}>{"Test Failure"}</h4>
            )
        }
}

export default TemperatureStatus;
