import React from 'react';
import TemperatureStatus from './temperatureStatus';

const displayText = (name, surname, time, date, device, styleText, styleColor, orientation, temperatureStatus, styleDeviceName) => {
    if (orientation === 'Vertical') {
        return (
            <div className={styleText} >
                <h1>{"Welcome "}</h1>
                <h1> {name}</h1>
                <h1> {surname}</h1>
                {TemperatureStatus(temperatureStatus)}
                <h4 className={styleDeviceName}>{device}</h4>
            </div>
        );
    } else {
        return (
            <div style={{ marginBottom: styleText }} >
                <h1 >{"Welcome "}{name} {surname}</h1>
                    {TemperatureStatus(temperatureStatus)}
                <h4 className={styleDeviceName}>{device}</h4>
            </div>
        );
    }
}

export default displayText;
