/* eslint-disable */
import React, { useEffect, useState } from 'react';
import "./index.css"
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ComponentsSceen from './componentsSceen';
import ErrorMessage from './errorMessage'
import imgs from '../img/logo512.png';

const Screen4 = (error, name, surname, img, index, date, device, time, deviceName, temperatureStatus) => {
    const windows = window.innerHeight
    const [screens, setScreens] = useState([
        { deviceNumber: null, deviceData: {} },
        { deviceNumber: null, deviceData: {} },
        { deviceNumber: null, deviceData: {} },
        { deviceNumber: null, deviceData: {} },
    ])

    const showScreen1 = screens[0].deviceData;
    const showScreen2 = screens[1].deviceData;
    const showScreen3 = screens[2].deviceData;
    const showScreen4 = screens[3].deviceData;

    const addDataToScreen = (i, deviceNumber, deviceData) => {
        setScreens(screens => {
            if (i !== -1) {
                screens[i] = {
                    deviceNumber,
                    deviceData
                }
            }
            return [...screens];
        });
    };

    useEffect(() => {
        function setData(deviceNumber, deviceData) {
            for (var i = 0; i < screens.length; i++) {
                if (!screens[i].deviceNumber) {
                    addDataToScreen(i, deviceNumber, deviceData)
                    break;
                }
            }
        }
        const indexData = screens.findIndex((screen) => screen.deviceNumber === device)
        if (indexData !== -1) {
            addDataToScreen(indexData, device, { device, name, surname, img, date, time, deviceName, temperatureStatus })
        } else if (device !== undefined) {
            setData(device, { device, name, surname, img, date, time, deviceName, temperatureStatus })
        }
    }, [device, surname, name, img, date, time, deviceName, temperatureStatus])

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        cardRight: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        cardLeft: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            height: windows / 2.135
        },
        display: {
        },
        color: {
            color: "#000000",
            fontSize: 25,
        },
        postionIMG: {
            width: "100%",
        },
        text: {
            gridColumnStart: "none",
            width: "100%",
            fontSize: 15,
        },
        styleDeviceName: {
            textAlign: "-webkit-right",
            color: "#cacaca",
            marginTop: windows / 3.70,
            fontSize: 11
        },
        styleLogoImg: {
            width: 70,
            position: "absolute",
            marginLeft: "46.5%",
            opacity: 0.5,
        }
    }));

    const classes = useStyles();
    const orientation = 'Vertical';

    return (
        <>
            {error ? <ErrorMessage /> : null}
            <Grid container spacing={2} xs={12} >
                <Grid className={classes.display} container xs={6}>
                    <img className={classes.styleLogoImg} src={imgs} />
                    {ComponentsSceen(0, showScreen1.name, showScreen1.surname, showScreen1.time, showScreen1.date, showScreen1.device, showScreen1.deviceName, classes.text, classes.color, orientation, classes.cardRight, classes.cardLeft, showScreen1.img, "100%", windows / 2.15, showScreen1.temperatureStatus, classes.styleDeviceName)}
                </Grid>
                <Grid className={classes.display} container xs={6}>
                    <img className={classes.styleLogoImg} src={imgs} />
                    {ComponentsSceen(1, showScreen2.name, showScreen2.surname, showScreen2.time, showScreen2.date, showScreen2.device, showScreen2.deviceName, classes.text, classes.color, orientation, classes.cardRight, classes.cardLeft, showScreen2.img, "100%", windows / 2.15, showScreen2.temperatureStatus, classes.styleDeviceName)}
                </Grid>
                <Grid className={classes.display} container xs={6}>
                    <img className={classes.styleLogoImg} src={imgs} />
                    {ComponentsSceen(2, showScreen3.name, showScreen3.surname, showScreen3.time, showScreen3.date, showScreen3.device, showScreen3.deviceName, classes.text, classes.color, orientation, classes.cardRight, classes.cardLeft, showScreen3.img, "100%", windows / 2.15, showScreen3.temperatureStatus, classes.styleDeviceName)}
                </Grid>
                <Grid className={classes.display} container xs={6}>
                    <img className={classes.styleLogoImg} src={imgs} />
                    {ComponentsSceen(3, showScreen4.name, showScreen4.surname, showScreen4.time, showScreen4.date, showScreen4.device, showScreen4.deviceName, classes.text, classes.color, orientation, classes.cardRight, classes.cardLeft, showScreen4.img, "100%", windows / 2.15, showScreen4.temperatureStatus, classes.styleDeviceName)}
                </Grid>
            </Grid>
        </>
    )
}

export default Screen4;
