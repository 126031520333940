import React from 'react';
import "./index.css"
import Alert from '@material-ui/lab/Alert';

const ErrorMessage = () => {
    return (
        <Alert severity="error" >Unable to connect to server!!!</Alert>
    );
}
export default ErrorMessage;
