import screen1 from './screen1';
import screen2 from './screen2';
import screen4 from './screen4';
import "./index.css"

const AccessDisplay = (error, name, surname, img, index, date, device ,deviceCount, time, diviceName, temperatureStatus) => {
    
    function display () {
        
        if (deviceCount === 1){
            return (
                screen1(error, name, surname, img, index, date, device, time, diviceName, temperatureStatus)
            );
        } else if (deviceCount === 2) {
            return (
                screen2(error, name, surname, img, index, date, device, time, diviceName, temperatureStatus)
            );
        } else if  (deviceCount === 4) {
            return (
                screen4(error, name, surname, img, index, date, device, time, diviceName, temperatureStatus)
            );
        }
    }

    return( 
        display()
        );
}

export default AccessDisplay;
