/* eslint-disable */
import React from 'react';
import "./index.css"
import Grid from '@material-ui/core/Grid';
import ComponentsSceen from './componentsSceen';
import { makeStyles } from '@material-ui/core/styles';
import ErrorMessage from './errorMessage'
import imgs from '../img/logo512.png';

const Screen1 = (error, name, surname, img, index, date, device, time, deviceName, temperatureStatus) => {
    const orientation = 'Vertical';
    const windows = window.innerHeight
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        cardRight: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        cardLeft: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            height: windows - 32 ,
        },
        color: {
            color: "#000000",
            fontSize: 31,
        },
        postionIMG: {
            width: "100%", 
        },
        text: {
            gridColumnStart: "none",
            height: windows - 140 ,
            width: "100%", 
            fontSize: 20,
        },
        styleDeviceName: {
            textAlign: "-webkit-right",
            color: "#cacaca",
            marginTop: windows/1.4,
        },
        styleLogoImg: {
            width: 70,
            position: "absolute",
            marginLeft: "96.2%",
            opacity: 0.5,
        }
    }));
    const classes = useStyles();
    return (
        <>
            {error ? <ErrorMessage/> : null}
            <Grid container xs={12}  >
                <img className={classes.styleLogoImg} src={imgs} />
                {ComponentsSceen(0, name, surname, time, date, device, deviceName, classes.text, classes.color, orientation, classes.cardRight, classes.cardLeft, img, "100%", windows - 36, temperatureStatus,classes.styleDeviceName)}
            </Grid>
        </>
    );
}

export default Screen1;
