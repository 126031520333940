import React from "react"
import "./App.css"
import HomeScreen from './scenes/index';

function App() {
	return (
	<HomeScreen/>
	)
}

export default App

